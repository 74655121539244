const BASE_URL = `https://newsapi.org/v2/everything?`;
const API_KEY = `f0bcd4370baf440fa9c118db8ad3780a`;




const NEWS_CATEGORY = {
  GENERAL: "general",
  BUSINESS: "business",
  ENTERTAINMENT: "entertainment",
  HEALTH: "health",
  SCIENCE: "science",
  SPORT: "sport",
  TECHNOLOGY: "technology",
};

export { BASE_URL, API_KEY, NEWS_CATEGORY };
