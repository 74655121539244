import axios from 'axios';
import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { API_KEY, BASE_URL } from '../utils';
import { BarLoader } from 'react-spinners';

const NewsCard = () => {
    const [selectedCategory, setSelectedCategory] = useState('health')
    const [page, setPage] = useState(1);
    const pageSize = 20;

    const fetchNews = async () => {
        try {
            const response = await axios.get(`${BASE_URL}q=${selectedCategory}&apiKey=${API_KEY}&pageSize=${pageSize}&page=${page}`);
            return response.data;
        } catch (error) {
            throw new Error(error.message);
        }
    };

    const { data: newsData, isLoading, isError, refetch } = useQuery(['News', selectedCategory, page], fetchNews);

    const handleCategoryClick = (category) => {
        setSelectedCategory(category)
        setPage(1);
        refetch();
    }
    const handlePageChange = (newPage) => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        setPage(newPage)
    }

    if (isLoading) {
        return <div className='flex flex-col justify-center items-center text-center w-full py-56 text-xl'>
            <BarLoader color="#be185d" className='mb-2' />
            Loading {selectedCategory} news...
        </div>;
    }

    if (isError) {
        return <div>Error loading news data</div>;
    }

    return (
        <div>
           <div className='flex flex-wrap justify-center md:justify-start p-4'>
                {['health', 'general', 'entertainment', 'business', 'sports', 'science', 'technology', 'bitcoin'].map((category) => (
                    <h6
                        key={category}
                        onClick={() => handleCategoryClick(category)}
                        className={`cursor-pointer border mx-2 my-2 ${selectedCategory === category ? 'bg-pink-700 text-white' : 'text-black hover:bg-pink-700 hover:text-white'} px-3 py-1 text-sm md:text-base rounded-lg transition-all`}
                    >
                        {category.charAt(0).toUpperCase() + category.slice(1)}
                    </h6>
                ))}
            </div>
            <div className='flex flex-wrap px-4'>
                {newsData.articles.map((article) => (
                    <div key={article.title} className="w-full md:w-1/2 lg:w-1/3 xl:w-1/4 p-4">
                        <div className="relative border rounded-lg overflow-hidden">
                            <img src={article.urlToImage} className="w-full h-44 object-cover" alt={article.title} />
                            <div className="p-4 h-40 overflow-hidden">
                                <h5 className="text-xl font-bold mb-2 line-clamp-3">{article.title}</h5>
                                <p className="text-gray-700 line-clamp-5">{article.description}</p>
                            </div>
                            <div className='p-4 flex justify-end'>
                                <a href={article.url} target="_blank" rel="noopener noreferrer" className="text-pink-700 font-bold  hover:bg-pink-700 hover:text-white px-2 py-1 rounded-lg transition-all">Read More...</a>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            <div className='flex justify-end items-center text-center mt-4 mb-4 px-8'>
                <button
                    onClick={() => handlePageChange(page - 1)}
                    disabled={page === 1}
                    className='mr-2 px-7 py-2 border rounded-md cursor-pointer'
                >
                    Previous
                </button>
                <button onClick={() => handlePageChange(page + 1)} className='px-7 py-2 border rounded-md cursor-pointer bg-pink-600 text-white' >
                    Next
                </button>
            </div>
            <div className='flex justify-center items-center text-center bg-pink-700 text-white py-4'>

                <h4 className="   text-white md:text-left text-center">
                    © {new Date().getFullYear()}{" "}
                    <span className=" underline cursor-pointer text-white text-md" >
                    <a href="https://www.technoweit.com/" target="_blank">Technoweit</a>
            
                    </span>{" "}
                    All rights reserved. Design & Developed by{" "}
                    <span className="  underline cursor-pointer text-white text-md">
                        <a href="https://harshalmm143.github.io/My_Portfolio/" target="_blank">Harshal Mahajan</a>
                    </span>
                    
                </h4>

            </div>

        </div>
    );
};

export default NewsCard;
