import React from 'react'

const Header = () => {
    return (
        <header className='bg-pink-700 w-full h-16 flex items-center justify-between px-4 md:px-10'>
            <div>
                <h5 className='text-white text-xl md:text-xl font-medium cursor-pointer'>The Technoweit Express</h5>
            </div>
        </header>
    )
}

export default Header