import React from 'react'
import Header from './components/Header'
import { QueryClient, QueryClientProvider } from 'react-query'
import NewsCard from './screens/NewsCard'


const queryClient = new QueryClient()
const App = () => {
  return (
    <div>
      <QueryClientProvider client={queryClient}>
        <Header />
        <NewsCard/>
      </QueryClientProvider>
    </div>
  )
}

export default App